<template>
  <div class="map padding--top-4 padding--bottom-4">
    <div class="row" v-if="map && map.image">
      <div class="col-12 bgimg--hover"
           :class="{ 'view': visible }"
           v-scroll:in="() => { visible = true; loaded = true }"
           v-scroll:out="() => visible = false">
        <div class="fade-in-child fade-in--bottom box-shadow">
          <background-image :key="map.image.handle" :scale="'16-9'" :url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/quality=value:70/${map.image.handle}`" :loaded="true"></background-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import BackgroundImage from './layout/BackgroundImage';
import Settings from '../config/settings';

export default {
  name: 'MapImage',
  components: { BackgroundImage },
  props: {
    id: {
      type: String,
      required: true
    },
    hasContainer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      Settings: Settings,
      map: {},
      loaded: false,
      visible: false
    };
  },
  methods: {
    async fetchMap () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleMap&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.map = response.moduleMap;
        });
    }
  },
  created () {
    this.fetchMap();
  }
};
</script>
