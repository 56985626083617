<template>
  <div class="event-guide padding--top-4 padding--bottom-8"
       v-if="loaded"
       v-scroll:in="() => { visible = true; }"
       v-scroll:out="() => visible = false">
    <div class="position-relative" :class="{'view': visible}">
      <div class="container-fluid">
        <h2 v-if="primaryHeadline"
            class="future-stage-headline text-uppercase text-italic text-primary fade-in-child fade-in--bottom delay--100"
            v-text="primaryHeadline"/>
        <h3 v-if="secondaryHeadline"
            class="future-stage-headline future-stage-headline--indent-left text-italic text-uppercase fade-in-child fade-in--bottom delay--200"
            v-text="secondaryHeadline"/>
      </div>
      <swiper class="event-guide__swiper-container margin--top-2" :ref="'eventGuideSlider'" :options="eventGuideSliderOptions">
        <swiper-slide class="bgimg--hover" v-for="(item, index) in scheduleItems" :key="'eventGuide-' + index">
          <div v-if="item.image" class="event-guide__swiper-slide fade-in-child fade-in--right delay--200">
            <background-image
              :scale="'16-9'"
              :overlay="true"
              :overlay-direction="'right'"
              :url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:1340,fit:crop/quality=value:70/${item.image.handle}`"
              :loaded="loaded"/>
            <div class="event-guide__text-overlay-absolute d-flex flex-column justify-content-start">
              <div class="event-guide__text-area">
                <div v-if="item.isLive" class="event-guide__live d-flex align-items-center">
                  <svg class="event-guide__live-icon" width="57" height="42" viewBox="0 0 57 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.3634 24.2626C30.2861 26.3385 26.9194 26.3385 24.8421 24.2626C22.7655 22.1868 22.7655 18.8208 24.8421 16.7449C26.9194 14.669 30.2861 14.669 32.3634 16.7449C34.44 18.8208 34.44 22.1868 32.3634 24.2626Z" fill="#DEFE81"/>
                    <path d="M41.068 33.4135C40.4044 33.4135 39.7409 33.1429 39.2357 32.601C38.2253 31.5199 38.2272 29.7681 39.2389 28.6891C41.2892 26.5024 42.4181 23.5954 42.4181 20.5039C42.4181 17.4123 41.2892 14.5053 39.2389 12.3186C38.2272 11.2389 38.2253 9.48786 39.2357 8.40605C40.2454 7.32494 41.8847 7.32284 42.8964 8.40256C45.927 11.6347 47.5958 15.9319 47.5958 20.5039C47.5958 25.0751 45.927 29.373 42.8964 32.6052C42.3912 33.1443 41.7296 33.4135 41.068 33.4135Z" fill="#DEFE81"/>
                    <path d="M47.1199 41.0075C46.4631 41.0075 45.8062 40.744 45.3061 40.2169C44.306 39.1634 44.3079 37.4581 45.3094 36.4067C49.358 32.1586 51.5883 26.511 51.5883 20.5039C51.5883 14.4967 49.358 8.84914 45.3094 4.60104C44.3079 3.54957 44.306 1.84433 45.3061 0.790818C46.3063 -0.262015 47.9283 -0.264058 48.9298 0.787413C53.9488 6.05362 56.7135 13.0557 56.7128 20.5039C56.7128 27.952 53.9488 34.9548 48.9298 40.221C48.4297 40.7453 47.7748 41.0075 47.1199 41.0075Z" fill="#DEFE81"/>
                    <path d="M16.1378 33.4135C15.4762 33.4135 14.8146 33.1443 14.3087 32.6052C11.2781 29.3731 9.60938 25.0752 9.60938 20.504C9.60938 15.932 11.2781 11.6349 14.3087 8.40281C15.3204 7.32311 16.9597 7.32451 17.9695 8.40631C18.9799 9.4874 18.9779 11.2391 17.9662 12.3188C15.9159 14.5055 14.7871 17.4124 14.7871 20.504C14.7871 23.5955 15.9159 26.5025 17.9662 28.6892C18.9779 29.7682 18.9799 31.5199 17.9695 32.601C17.4643 33.1422 16.8007 33.4135 16.1378 33.4135Z" fill="#DEFE81"/>
                    <path d="M10.0863 41.0075C9.4307 41.0075 8.7758 40.7453 8.27572 40.2203C3.25674 34.9541 0.492676 27.9521 0.492676 20.504C0.492676 13.0553 3.25674 6.05323 8.27572 0.787066C9.27718 -0.263715 10.8999 -0.262353 11.8994 0.791151C12.8996 1.84398 12.8976 3.5492 11.8961 4.60066C7.84754 8.84873 5.61724 14.4963 5.61724 20.5033C5.61724 26.5104 7.84754 32.1586 11.8961 36.4067C12.8976 37.4582 12.8996 39.1634 11.8994 40.2169C11.3993 40.744 10.7425 41.0075 10.0863 41.0075Z" fill="#DEFE81"/>
                  </svg>
                  <span class="event-guide__live-text text-uppercase font-weight--bold text-primary" v-text="$t('futureStage.nowLive')"/>
                </div>
                <div v-if="!item.isLive">
                  <div class="event-guide__start-date text-uppercase font-weight--bold text-primary" v-text="getFormattedStartDate(item.startDate)"/>
                </div>
                <h3 class="event-guide__title" v-text="item.title"/>
              </div>
            </div>
            <div class="event-guide__text-overlay-absolute d-flex flex-column justify-content-end">
              <div v-if="item.link && item.link.url" class="event-guide__text-area">
                <a
                  class="event-guide__read-more button-ghost primary text-uppercase margin--bottom-2"
                  :href="item.link.url"
                  v-text="item.link.title ? item.link.title : $t('futureStage.moreInfo')"/>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="d-flex justify-content-center align-items-end">
        <div class="swiper-pagination eventGuidePaginator"></div>
      </div>
      <break-after/>
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';
import BreakAfter from '../../components/layout/BreakAfter';
import BackgroundImage from '../../components/layout/BackgroundImage';

export default {
  name: 'EventGuide',
  components: { BackgroundImage, BreakAfter },
  data () {
    return {
      Settings: Settings,
      visible: false,
      loaded: false,
      scheduleItems: [],
      eventGuideSlider: false,
      primaryHeadline: null,
      secondaryHeadline: null,
      eventGuideSliderOptions: {
        lazy: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        initialSlide: 1,
        autoplay: {
          delay: 4000
        },
        pagination: {
          el: '.eventGuidePaginator',
          dynamicBullets: true,
          clickable: true
        }
      }
    };
  },
  methods: {
    async fetchScheduleItems () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=scheduleItems',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.scheduleItems = response.scheduleItems ? response.scheduleItems : [];
          const dateNow = new Date();
          this.scheduleItems.forEach(item => {
            if (this.primaryHeadline === null) {
              this.primaryHeadline = item.headline && item.headline.primary ? item.headline.primary : null;
            }
            if (this.secondaryHeadline === null) {
              this.secondaryHeadline = item.headline && item.headline.secondary ? item.headline.secondary : null;
            }
            const startDate = new Date(item.startDate);
            const endDate = new Date(item.endDate);
            item.isLive = false;
            if (startDate <= dateNow && endDate >= dateNow) {
              item.isLive = true;
            }
            this.loaded = true;
          });
        });
    },
    getFormattedStartDate (itemDate) {
      const startDate = new Date(itemDate);
      const month = startDate.getMonth() + 1;
      const day = ('00' + startDate.getDate()).slice(-2);
      const year = startDate.getFullYear();
      const hours = ('00' + startDate.getHours()).slice(-2);
      const minutes = ('00' + startDate.getMinutes()).slice(-2);
      return `${day}.${month}.${year} ${hours}.${minutes}`;
    }
  },
  watch: {
    $route () {
      this.fetchScheduleItems();
    }
  },
  created () {
    this.fetchScheduleItems();
  }
};
</script>

<style scoped lang="scss">
  .event-guide {
    $root: &;
    background: url('~@/assets/images/wheel-track-horizontal.svg') top repeat-x;
    width: 100vw;
    height: auto;

    &__swiper-container {
      padding-bottom: 40px;
    }

    &__text-overlay-absolute {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__text-area {
      max-width: 70%;
      padding: 60px 0 60px 120px;
    }

    &__title {
      position: relative;
      color: #fff;
      margin-top: 30px;
      margin-bottom: 0;
    }

    &__read-more {
      max-width: 30%;
      -webkit-transform: skewX(-13deg);
      transform: skewX(-13deg);
    }

    &__swiper-slide {
      padding: $gap * 2 $gap * 2;
    }

    .swiper-slide {
      width: 70vw;
      max-width: 1100px;
    }

    &__live {
      margin-top: 20px;
    }

    &__live-text {
      font-size: 22px;
      padding-left: 16px;
    }

    &__start-date {
      font-size: 22px;
      margin-top: 20px;
    }

    // Responsive stuff here
    @media all and (max-width: map_get($grid-breakpoints, 'md')) {

      &__text-area {
        padding: 0 0 0 40px;
        max-width: 100%;
      }

      .swiper-slide {
        width: 100%;
      }

      &__swiper-slide {
        padding: 0;
      }

      &__read-more {
        max-width: 40%;
      }

      &__live-text {
        font-size: 1rem;
        padding-left: 16px;
      }

      &__start-date {
        font-size: 1rem;
      }

      &__live-icon {
        width: 40px;
        height: 30px;
      }
    }

    @media all and (max-width: map-get($grid-breakpoints, 'lg')) {
      &__text-area {
        max-width: 100%;
      }
    }
  }
</style>
