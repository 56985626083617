<template>
  <div class="fade-in-child fade-in--bottom delay--200">
    <swiper class="preference-slider padding--top-md-2 padding--top-4 margin--bottom-5" :ref="`question${question.key}`" :options="swiper[`question${question.key}`]">
      <template v-for="(tag, count) in question.tags">
        <swiper-slide :key="'question-' + question.key + '-' + count" v-if="tag.crmId">
          <div class="preference-slider-slide position-relative" :class="{'preference-slider-slide--card': !tag.hasImage && !tag.hasStaticImage }">
            <div v-if="!tag.hasImage && !tag.hasStaticImage" class="h5 text-align--center padding--top-2 padding--bottom-2">
              {{ tag.title }}
            </div>
            <img v-if="tag.hasImage"
                 :src="tag.hasImage && tag.imageObject.image ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:650,fit:crop/quality=value:70/${tag.imageObject.image.handle}` : pixel"
                 width="100%"
                 :alt="tag.imageObject.imageAlt"
                 :title="tag.imageObject.imageTitle">
            <img v-if="tag.hasStaticImage"
                 :src="getStaticImage(tag)" :alt="tag.title"
                 class="preference-slider__static-image"
                 width="100%">
            <div class="preference-slider-slide__manufacturer text-align--center">
              <h3 v-if="tag.hasImage" class="bg-primary h5"><span>{{ tag.title }}</span></h3>
            </div>
            <div class="text-align--center">
              <div class="preference-slider-slide__favorite"
                   :class="{ active: question.currentAnswer === tag.id }"
                   @click="onUpdateAnswer(question, tag)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17">
                  <use href="#dtm-heart" x="0" y="0" />
                </svg>
              </div>
            </div>
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import Settings from '@/config/settings';

export default {
  name: 'PreferenceEntry',
  props: {
    question: {
      type: Object,
      required: true
    },
    image: {
      type: Object,
      required: false
    },
    objectName: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      Settings: Settings,
      swiper: this.$store.getters['adb2c/preferenceSwiper']
    };
  },
  methods: {
    onUpdateAnswer (question, tag) {
      this.$emit('update-answer', { question, tag });
    },
    getStaticImage (tag) {
      const imageName = tag.title.replaceAll(' ', '');
      return require(`../../assets/images/${imageName}.svg`);
    }
  }
};
</script>

<style lang="scss">
.preference-slider {
  $root: &;
  &-slide {
    transition: all .3s;
    opacity: 0;
    transform: scale(0);
    &__name {
      transform: translate(0, -1.8em);
      h3 {
        font-size: 1.8em;
      }
    }
    &__manufacturer {
      h3 {
        font-size: 1.2em;
      }
    }
    &--card {
      background: rgba($color-bg-dark, 0.3);
      padding: $gap;
    }
    &__favorite {
      display: inline-block;
      position: relative;
      border-radius: 100%;
      width: 45px;
      height: 45px;
      border: 1px solid rgba($color-primary, .5);
      transition: all .3s;
      cursor: pointer;
      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%) scale(0.9);
        transition: all .3s;
        path, use {
          fill: rgba($color-primary, 0);
          stroke: $color-primary;
          stroke-width: 1;
          transition: all .3s;
        }
      }
      &:hover {
        border: 1px solid rgba($color-primary, 1);
        svg {
          transform: translate(-50%, -50%) scale(1);
          path, use {
            stroke-width: 2;
          }
        }
      }
      &.active {
        border: 1px solid rgba($color-primary, 1);
        box-shadow: 0 0 15px rgba($color-primary, 0.6);
        svg {
          transform: translate(-50%, -50%) scale(1);
          path, use {
            fill: rgba($color-primary, 1);
            stroke-width: 1;
          }
        }
      }
    }
  }
  .swiper-slide {
    width: 300px;
    max-width: 50vw;
    &-active {
      #{$root}-slide {
        opacity: 1;
        transform: scale(1);
      }
    }
    &-next, &-prev {
      #{$root}-slide {
        transform: scale(.7);
        opacity: .6;
      }
    }
  }
  &__static-image {
    height: 120px;
  }
}
</style>
