<template>
  <div class="vertical-slider"
       v-if="sliderData"
       :class="{ 'view': visible }"
       v-scroll:in="() => { visible = true; loaded = true; swiper = true }"
       v-scroll:out="() => visible = false">
    <div class="h3 padding--bottom-2  text-uppercase bg-primary">
      <span> {{ sliderData.headline }}</span>
    </div>
    <div>
      <swiper class="vertical-slider__swiper padding--bottom-2" :class="className" :ref="swiper" :options="swiperOptions">
        <swiper-slide v-for="item in sliderData.items" :key="item.image.handle">
          <div class="bgimg--hover">
            <div class="fade-in-child">
              <background-image
                :scale="'3-4'"
                :url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:1200,fit:crop/quality=value:70/${item.image.handle}`"
                :loaded="loaded"/>
            </div>
          </div>
          <div class="vertical-slider__text-wrapper" v-if="item.description">
            <p v-text="item.description"/>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import BackgroundImage from '@/components/layout/BackgroundImage';
import Settings from '../config/settings';

export default {
  name: 'VerticalSlider',
  components: { BackgroundImage },
  props: {
    className: {
      type: String,
      default: ''
    },
    sliderData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      Settings: Settings,
      visible: false,
      loaded: false,
      swiper: false,
      swiperOptions: {
        spaceBetween: 15,
        lazy: true,
        speed: 600,
        centeredSlides: false,
        slidesPerView: 3,
        autoplay: {
          delay: 4000
        },
        breakpoints: {
          769: {
            slidesPerView: 3
          },
          500: {
            slidesPerView: 'auto'
          },
          0: {
            slidesPerView: 'auto'
          }
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@media all and (max-width: map_get($grid-breakpoints, 'sm')) {
  .vertical-slider {
    .swiper-slide {
      width: 80%;
    }
  }
}
@media (min-width: map_get($grid-breakpoints, 'md')) {
  .vertical-slider {
    &--driverDetail {
      .swiper-slide {
        &:nth-child(3n-2) {
          padding-top: $gap * 4;
        }
        &:nth-child(3n-1) {
          padding-top: $gap * 8;
        }
      }
    }
  }
}
</style>
