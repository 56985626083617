<template>
  <div class="info-boxes margin--top-2 padding--bottom-4"
       :class="{ 'view': visible }"
       v-scroll:in="() => { visible = true; loaded = true }"
       v-scroll:out="() => visible = false">
    <div class="row d-flex justify-content-center">
      <div  v-if="this.topline" class="col-12">
        <div class="h4 font-weight--light text-italic margin--bottom-1" v-text="this.topline"></div>
      </div>
      <div v-if="this.headline" class="col-12">
        <div class="h2 margin--bottom-2" v-text="this.headline"></div>
      </div>
      <div
        class="col-12 col-md-4 d-flex bgimg--hover margin--bottom-1"
        v-for="(infoBox, index) in infoBoxes" :key="index">
        <div v-if="infoBox.image" class="info-boxes__link fade-in-child fade-in--bottom box-shadow">
          <a
            :href="infoBox.link.url || infoBox.link.external"
            target="_blank" :title="infoBox.link.title">
            <background-image
              :url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/quality=value:70/${infoBox.image.handle}`"
              :scale="'16-9'"
              :title="infoBox.title"
              :loaded="loaded"/>
          </a>
        </div>
        <div v-if="!infoBox.image" class="bg-default col-12 padding-2 d-flex align-items-start flex-wrap info-boxes__col">
          <div class="col-12">
            <h4 class="primary padding--bottom-1" v-text="infoBox.headline"/>
          </div>
          <div class="col-12">
            <div class="text-white padding--bottom-1" v-html="infoBox.description.html"/>
          </div>
          <div class="col-12 align-self-end">
            <a
              v-if="!infoBox.link.external"
              :href="infoBox.link.url"
              class="link--more"
              v-text="infoBox.link.title"/>
            <a
              v-if="infoBox.link.external" :href="infoBox.link.url"
              class="link--more"
              target="_blank"
              v-text="infoBox.link.title"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';
import BackgroundImage from '@/components/layout/BackgroundImage';

export default {
  name: 'InfoBoxes',
  components: { BackgroundImage },

  props: {
    ids: {
      type: Array,
      required: false
    },
    topline: {
      type: String,
      required: false
    },
    headline: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      Settings: Settings,
      infoBoxes: [],
      visible: false,
      loaded: false
    };
  },
  computed: {
    idParameters: function () {
      return this.ids.join();
    }
  },
  methods: {
    async fetchInfoBoxes () {
      const idParameters = this.idParameters;
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleInfoBoxes&ids=${idParameters}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.infoBoxes = response.infoBoxes;
        });
    }
  },
  created () {
    if (this.ids.length > 0) {
      this.fetchInfoBoxes();
    } else {
      this.infoBoxes = this.data;
    }
  }
};
</script>
<style lang="scss" scoped>
  .info-boxes {
    &__col {
      border-bottom: 12px solid $color-primary;
    }

    &__link {
      width: 100%;
      height: auto;
    }
  }
</style>
