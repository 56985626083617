const settings = {
  baseUrl: 'https://sw-test.d7mcl0ud.de',
  nodeApi: {
    uri: 'https://aocdchk73l.execute-api.eu-central-1.amazonaws.com/prod/'
  },
  region: 'eu-central-1-dtm',
  environmentId: 'AIRQs12o3S4iH0m7FCWATz',
  LIFETIME_DEFAULT: 0,
  LIFETIME_STATIC: 0,
  LIFETIME_SHORT: 0,
  azure: {
    credentials: {
      tenantName: 'b2cdtmdev',
      clientId: '9afa2980-81f5-46ed-a4f2-8fc9c3edc7b3'
    },
    policies: {
      signUpSignIn: 'b2c_1a_signup_signin',
      editProfile: 'b2c_1a_profileedit',
      passwordReset: 'b2c_1a_passwordrese'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://b2cdtmdev.b2clogin.com/b2cdtmdev.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_signup_signin'
      },
      editProfile: {
        authority: 'https://b2cdtmdev.b2clogin.com/b2cdtmdev.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_profileedit'
      },
      passwordReset: {
        authority: 'https://b2cdtmdev.b2clogin.com/b2cdtmdev.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_passwordreset'
      }
    }, // we generate them later
    authorityDomain: 'b2cdtmdev.onmicrosoft.com',
    loginDomain: 'b2cdtmdev.b2clogin.com',
    redirectUri: 'https://localhost:8080/redirect',
    webApiUri: '',
    metadata: {
      authority: 'login.microsoftonline.com',
      discovery: '',
      version: ''
    }
  }
};
export default settings;
