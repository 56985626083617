<template>
  <div class="position-relative news-overview">
    <div class="container-fluid highlight-top-spacer" :class="classList" v-if="newses.length || newsesHighlight.length">
      <div v-if="width >= 768">
        <h2 class="text-italic text-dark">{{ $t('newsOverview.title') }}</h2>
        <div class="row">
          <div v-for="(news, count) in newsesHighlight"
               :key="news.slug"
               class="col-12 margin--bottom-2 bgimg--hover"
               :class="{ 'view': news.visible, 'col-md-6 col-lg-4': count > 0 }"
               v-scroll:in="() => { news.visible = true; news.loaded = true }"
               v-scroll:out="() => news.visible = false">
            <router-link class="row" :to="{ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} }">
              <div class="col-8">
                <div class="fade-in-child fade-in--right delay--100">
                  <background-image
                    :scale="'4-3'"
                    :overlay="true"
                    :overlay-direction="'right'"
                    :url="news.image ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:1200,fit:crop/output=format:jpg/quality=value:70/${news.image.handle}` : news.videoPosterImage ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:1200,fit:crop/output=format:jpg/quality=value:70/${news.videoPosterImage.handle}` : null"
                    :loaded="news.loaded"
                    :video="news.cardVideo"/>
                </div>
              </div>
              <div class="col-4">
                <div class="highlight-content">
                  <div class="fade-in-child fade-in--right delay--300">
                    <h3 class="bg-primary"><span>{{ news.headline }}</span></h3>
                  </div>
                </div>
                <div v-html="news.excerpt" class="fade-in-child fade-in--right delay--400 margin--bottom-1 margin--top-1 text-dark" v-if="news.excerpt"></div>
                <div class="highlight-content" style="padding-top: 0;">
                  <div class="fade-in-child fade-in--right delay--500" style="text-shadow: 0 0 5px rgba(0, 0, 0, 0.5)">
                    <router-link class="link--more"
                                 :to="{ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} }">
                      {{ $t('newsOverview.moreInfo') }}
                    </router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div v-for="(news) in newses"
               :key="news.slug"
               class="col-4 margin--bottom-2 bgimg--hover"
               :class="{ 'view': news.visible }"
               v-scroll:in="() => { news.visible = true; news.loaded = true }"
               v-scroll:out="() => news.visible = false">
            <router-link :to="{ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} }">
              <div class="fade-in-child fade-in--bottom">
                <background-image
                  :scale="'16-9'"
                  :url="news.image ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:900,fit:crop/output=format:jpg/quality=value:70/${news.image.handle}` : news.videoPosterImage ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:900,fit:crop/output=format:jpg/quality=value:70/${news.videoPosterImage.handle}` : null"
                  :loaded="news.loaded"
                  :video="news.cardVideo"/>
              </div>
              <div class="news-content text-default padding-1 padding--top-0">
                <div class="fade-in-child fade-in--bottom delay--100">
                  <div class="text topline text-uppercase bg-primary bg-primary--condensed"><span>{{ news.topline }}</span></div>
                </div>
                <div class="fade-in-child fade-in--bottom delay--200">
                  <h4>{{ news.headline }}</h4>
                </div>
                <div class="fade-in-child fade-in--bottom delay--300">
                  <router-link class="text-default link--more" :to="{ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} }">
                    {{ $t('newsOverview.moreInfo') }}
                  </router-link>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="width < 768">
      <swiper ref="newsSwiper" :options="newsSwiperOptions">
        <!-- Slides -->
        <swiper-slide class="news-swiper" v-for="(news) in newsesHighlight" :key="news.slug">
          <div class="news-swiper__slide col-md-6 col-lg-4"
               :class="{ 'view': news.visible }"
               v-scroll:in="() => { news.visible = true; news.loaded = true }"
               v-scroll:out="() => news.visible = false">
            <router-link :to="{ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} }" class="bgimg--hover padding--top-1 padding--bottom-1">
              <div class="fade-in-child">
                <background-image
                  :scale="'16-9'"
                  :url="news.image ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:900,fit:crop/output=format:jpg/quality=value:70/${news.image.handle}` : news.videoPosterImage ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:900,fit:crop/output=format:jpg/quality=value:70/${news.videoPosterImage.handle}` : null"
                  :loaded="news.loaded"
                  :video="news.cardVideo"/>
              </div>
              <div class="news-content text-dark padding-1 padding--top-0">
                <div class="fade-in-child delay--100">
                  <div class="text topline bg-primary bg-primary--condensed"><span>{{ news.topline }}</span></div>
                </div>
                <div class="fade-in-child delay--200">
                  <p>{{ news.headline }}</p>
                </div>
                <div class="fade-in-child delay--300">
                  <router-link class="link--more" :to="{ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} }">
                    {{ $t('newsOverview.moreInfo') }}
                  </router-link>
                </div>
              </div>
            </router-link>
          </div>
        </swiper-slide>
        <swiper-slide class="news-swiper" v-for="(news) in newses" :key="news.slug">
          <div class="news-swiper__slide col-md-6 col-lg-4"
               :class="{ 'view': news.visible }"
               v-scroll:in="() => { news.visible = true; news.loaded = true }"
               v-scroll:out="() => news.visible = false">
            <router-link :to="{ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} }" class="bgimg--hover padding--top-1 padding--bottom-1">
              <div class="fade-in-child">
                <background-image
                  :scale="'16-9'"
                  :url="news.image ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:900,fit:crop/output=format:jpg/quality=value:70/${news.image.handle}` : news.videoPosterImage ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:900,fit:crop/output=format:jpg/quality=value:70/${news.videoPosterImage.handle}` : null"
                  :loaded="news.loaded"
                  :video="news.cardVideo"/>
              </div>
              <div class="news-content text-dark padding-1 padding--top-0">
                <div class="fade-in-child delay--100">
                  <div class="text topline bg-primary bg-primary--condensed"><span>{{ news.topline }}</span></div>
                </div>
                <div class="fade-in-child delay--200">
                  <p>{{ news.headline }}</p>
                </div>
                <div class="fade-in-child delay--300">
                  <router-link class="link" :to="{ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} }">
                    {{ $t('newsOverview.moreInfo') }}
                  </router-link>
                </div>
              </div>
            </router-link>
          </div>
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
    <div class="container-fluid margin--bottom-8">
      <div class="row justify-content-center">
        <div class="col-auto">
          <router-link :to="newsOverviewRoute"
                       class="text-uppercase button-ghost">
            {{ $t('newsOverview.newsReadMore') }}
          </router-link>
        </div>
      </div>
    </div>
    <break-after v-if="breakAfter" :key="'breakAfter'"></break-after>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import BackgroundImage from '../layout/BackgroundImage';
import BreakAfter from '../layout/BreakAfter';
import Settings from '../../config/settings';

export default {
  name: 'NewsOverview',
  components: { BackgroundImage, BreakAfter },
  props: {
    breakAfter: { type: Boolean, default: false },
    classList: { type: String, default: '' },
    isDtmTrophy: { type: Boolean, default: false }
  },
  data () {
    return {
      Settings: Settings,
      loading: false,
      newsesHighlight: [],
      newses: [],
      highlights: [],
      width: 0,
      height: 0,
      newsSwiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 0
      },
      raceSeries: this.$store.getters['page/raceSeries']
    };
  },
  computed: {
    queryNameNews: function () {
      return this.$store.getters['page/raceSeriesNews'];
    },
    queryNameNewsHighlights: function () {
      return this.$store.getters['page/raceSeriesNewsHighlights'];
    },
    newsOverviewRoute: function () {
      return this.isDtmTrophy ? `/${this.$i18n.locale}/news/dtmtrophy` : `/${this.$i18n.locale}/news`;
    }
  },
  methods: {
    // get the news
    async getNewsHighlight () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=${this.queryNameNewsHighlights}&count=1`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.newses.forEach((news) => {
            news.visible = false;
            news.loaded = false;
          });
          this.newsesHighlight = response.newses;
          this.getNews();
        });
    },
    async getNews () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=newsAll&count=3&offset=0&exclude=${this.getExcludes().join(',')}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.newses.forEach((news) => {
            news.visible = false;
            news.loaded = false;
          });
          this.newses = response.newses;
        });
    },
    getExcludes () {
      const excludes = [];
      this.newsesHighlight.forEach(news => {
        excludes.push(news.id);
      });
      return excludes;
    },
    resize (values) {
      this.width = values[0];
      this.height = values[1];
    }
  },
  watch: {
    '$route.params.pathMatch': function () {
      this.newses = [];
      this.newsesHighlight = [];
      this.getNewsHighlight();
    }
  },
  mounted () {
    this.getNewsHighlight();
    this.resize(this.$store.getters['scroll/resize']);
  },
  created () {
    // watch window resize
    this.unwatchResize = this.$store.watch(() => this.$store.getters['scroll/resize'], resize => {
      this.resize(resize);
    });
    this.unwatchRaceSeries = this.$store.watch(() => this.$store.getters['page/raceSeries'], raceSeries => {
      // if not in current raceSeries fetch new navigation for different context
      if (this.raceSeries !== raceSeries) {
        this.raceSeries = raceSeries;
        this.newses = [];
        this.newsesHighlight = [];
        this.getNewsHighlight();
      }
    });
  },
  destroyed () {
    this.unwatchResize();
    this.unwatchRaceSeries();
  }
};
</script>

<style lang="scss" scoped>
  .highlight-top-spacer {
    margin-top: 120px;
  }
  .highlight-content {
    padding-top: $gap * 4;
    margin-left: -33%;
    font-size: 19px;
  }
  .news-content {
    font-size: 19px;
  }
  .topline {
    margin-top: -1em;
  }
  .news-swiper {
    width: calc(100vw - 60px);
  }
</style>
