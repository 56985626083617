<template>
  <div class="event-sponsor-banner padding--top-10">
    <div class="overflow-hidden position-relative">
      <background-image
        v-if="sponsorBanner && sponsorBanner.handle"
        :scale="'original'"
        :url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:1340,fit:crop/output=format:jpg/quality=value:70/${sponsorBanner.handle}`"
        :loaded="true"
        :scrollScale="false"
        :preloadWhite="true"/>
    </div>
  </div>
</template>

<script>
import BackgroundImage from '@/components/layout/BackgroundImage';
import Settings from '@/config/settings';
export default {
  name: 'EventSponsorBanner',
  components: { BackgroundImage },
  props: {
    sponsorBanner: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      Settings: Settings
    };
  }
};
</script>

<style scoped>

</style>
