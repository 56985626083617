<template>
  <div class="livestream"
       v-if="scheduleData"
       v-scroll:in="() => { visible = true; wasVisible = true; }"
       v-scroll:out="() => visible = false">
    <div class="position-relative">
      <page-header
        :background-image="!streamUri && stageImage ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:2800,fit:crop/output=format:jpg/quality=value:70/${stageImage.handle}` : null"
        :youtube-stream-url="streamUri"
        :class-list="'page-header--16by9'"
        :break-after="false"
        :loading="loading"/>
      <div v-if="!streamUri && stageImage" class="position-absolute livestream__headline-wrapper d-flex align-items-center" :class="{'view': visible}">
        <div class="container-fluid">
          <h2 v-if="scheduleData.title"
              class="future-stage-headline text-uppercase text-italic text-primary fade-in-child fade-in--bottom delay--200"
              v-text="scheduleData.title"/>
          <h3 v-if="scheduleData.subHeadline"
              class="future-stage-headline future-stage-headline--indent-left text-italic text-uppercase fade-in-child fade-in--bottom delay--300"
              v-text="scheduleData.subHeadline"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';
import PageHeader from '../../components/layout/PageHeader';

export default {
  name: 'Livestream',
  components: { PageHeader },
  data () {
    return {
      Settings: Settings,
      loading: true,
      visible: false,
      scheduleData: {}
    };
  },
  computed: {
    streamUri: function () {
      return this.scheduleData.streamUri ? this.scheduleData.streamUri : null;
    },
    stageImage: function () {
      return (this.scheduleData.stageImage && this.scheduleData.stageImage.url) ? this.scheduleData.stageImage : null;
    },
    title: function () {
      return this.scheduleData.title ? this.scheduleData.title : null;
    },
    subHeadline: function () {
      return this.scheduleData.subHeadline ? this.scheduleData.subHeadline : null;
    }
  },
  methods: {
    async fetchSchedule () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=schedule',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.scheduleData = response.schedules && response.schedules.length > 0 ? response.schedules[0] : null;
          this.loading = false;
        });
    }
  },
  watch: {
    $route () {
      this.fetchSchedule();
    }
  },
  created () {
    this.fetchSchedule();
  }
};
</script>
<style lang="scss" scoped>
  .livestream {
    &__headline-wrapper {
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
    }
  }

</style>
