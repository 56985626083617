<template>
  <div class="racecalendar">
    <meta-data v-if="page.metaTitle"
      :key="page.metaTitle"
      :meta-title="page.metaTitle"
      :meta-description="page.metaDescription"
      :no-index="page.noIndex"/>
    <page-header
      :headline="$t('raceCalendar.title')"
      :sub-headline="$t('raceCalendar.subtitle')"
      :break-after-to-white="true"
      :background-image="randomEvent ? `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:2800,fit:crop/output=format:jpg/quality=value:70/blur=amount:2/${randomEvent.tileImage.handle}` : ''"/>
    <div class="bg-white text-default">
      <loader :inverted="true" v-if="loading"></loader>
      <div class="container-fluid padding--top-2">
        <template v-for="(moduleEntry) in page.modules">
          <plain-text v-if="moduleEntry.module === 'text'" :key="`text-${moduleEntry.id}`" :id="moduleEntry.id" :class="'text-default'"/>
          <cta v-if="moduleEntry.module === 'CTA'" :key="`cta-${moduleEntry.id}`" :id="moduleEntry.id"/>
        </template>
        <div v-if="upcomingEvents.length > 0" class="bg-default text-white padding-2 margin--top-0 margin--bottom-2 h4 text-normal">
          {{ $t('raceCalendar.upcoming') }}
        </div>
        <event-list
          :events="upcomingEvents"
          :key="'upcoming'"/>
        <div v-if="pastEvents.length > 0" class="bg-dark-gray text-white padding-2 margin--top-0 margin--bottom-2 h4 text-normal">
          {{ $t('raceCalendar.past') }}
        </div>
        <event-list
          :events="pastEvents"
          :background="'bg-dark-gray'"
          :overlay-color="'bg-dark-gray'"
          :key="'past'"/>
        <template v-for="(moduleEntry) in page.modules">
          <image-text v-if="moduleEntry.module === 'imageText'" :key="`image-text-${moduleEntry.id}`" :id="moduleEntry.id" :class="'text-default'"/>
          <image-gallery v-if="moduleEntry.module === 'gallery'" :key="`gallery-${moduleEntry.id}`" :id="moduleEntry.id" :slidesPerView="moduleEntry.slidesPerView || 1"/>
          <info-boxes v-if="moduleEntry.module === 'infoBoxes'" :key="`info-boxes-${moduleEntry.id}`" :ids="moduleEntry.ids"/>
          <map-image v-if="moduleEntry.module === 'map'" :key="`map-${moduleEntry.id}`" :id="moduleEntry.id" :has-container="true"/>
          <cta v-if="moduleEntry.module === 'CTA'" :key="`cta-${moduleEntry.id}`" :id="moduleEntry.id"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import EventList from '../components/events/EventList';
import PageHeader from '../components/layout/PageHeader';
import MetaData from '../components/MetaData';
import Cta from '../components/Cta';
import MapImage from '../components/MapImage';
import Loader from '../components/layout/Loader';
import PlainText from '../components/PlainText';
import ImageText from '../components/ImageText';
import ImageGallery from '../components/ImageGallery';
import InfoBoxes from '../components/InfoBoxes';
import Settings from '../config/settings';

export default {
  name: 'RaceCalendar',
  components: {
    InfoBoxes,
    ImageGallery,
    ImageText,
    PlainText,
    Loader,
    MapImage,
    Cta,
    MetaData,
    EventList,
    PageHeader
  },
  data () {
    return {
      Settings: Settings,
      loading: true,
      events: [],
      headlineVisible: false,
      page: {}
    };
  },
  computed: {
    upcomingEvents: function () {
      return this.events.filter(event => {
        const now = new Date();
        const eventEndDate = new Date(event.endTime);
        const eventStartDate = new Date(event.startTime);
        return (eventStartDate && eventEndDate > now && event.eventNumber && !event.hideOnOverview);
      });
    },
    pastEvents: function () {
      return this.events.filter(event => {
        const now = new Date();
        const eventEndDate = new Date(event.endTime);
        const eventStartDate = new Date(event.startTime);
        return (eventStartDate && eventEndDate < now && !event.hideOnOverview) || (!event.eventNumber && !event.hideOnOverview);
      });
    },
    randomEvent: function () {
      return this.events[Math.floor(Math.random() * this.events.length)];
    }
  },
  methods: {
    async fetchSystemPage () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=systemPages&slug=events',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.page = response.systemPages[0];
          this.loading = false;
        })
        .catch(() => {
          // setTimeout(this.fetchSystemPage, 3000);
        });
    },
    async fetchEvents () {
      await this.$store.dispatch('page/raceSeriesLoaded', true);
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=events',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.events.forEach((event) => {
            event.viewPort = false;
            this.loading = false;
          });
          this.events = response.events;
          this.$store.commit('page/SET_RACESERIES', (['DTM']));
          this.$store.dispatch('page/raceSeriesLoaded', false);
        })
        .catch(() => {
          // setTimeout(this.fetchEvents, 3000);
        });
    }
  },
  created () {
    this.fetchSystemPage();
    this.fetchEvents();
  }
};
</script>

<style lang="scss" scoped>
  // none yet
</style>
