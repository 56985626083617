<template>
  <div class="racecalendar">
    <div v-if="!error">
      <meta-data v-if="event && event.metaTitle"
        :key="event.metaTitle"
        :meta-title="event.metaTitle"
        :meta-description="event.metaDescription"/>
      <page-header
        :layout="'event'"
        :headline="loading ? ' ' : event.name"
        :sub-headline="loading || event.hideEventDate ? ' ' : event.eventNumber ? ('00' + event.eventNumber).slice(-2) : ''"
        :event-days="loading || event.hideEventDate ? '' : (event && event.startTime) ? event.days : ''"
        :event-months="loading || event.hideEventDate ? '' : (event && event.startTime) ? event.months : ''"
        :event-track="loading ? '' : event.racetrack.circuit.url"
        :buttons="loading ? [] : event.links"
        :background-image="loading ? '' : `https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:2800,fit:crop/output=format:jpg/quality=value:70/${event.headerImage.handle}`"
        :flag="loading ? '' : event.country && event.country.appCountryFlag ? event.country.appCountryFlag.url : null"
        :will-have-background-image="true"
        :break-after-to-white="true"
        :loading="loading"/>
      <div class="bg-white text-default">
        <loader :inverted="true" v-if="loading"></loader>
        <countdown v-if="event && event.startTime" :date="loading ? null : event.startTime"/>
        <div class="container-fluid" v-if="!loading">
          <event-sponsor-banner
            v-if="event && event.sponsorBanner"
            :key="event.id"
            :sponsor-banner="event.sponsorBanner"
          />
        </div>
        <div class="container-fluid padding--top-4 padding--bottom-8" v-if="!loading">
          <div class="h4 font-weight--light text-italic margin--bottom-1 margin--top-3" v-if="event.introTopline">{{ event.introTopline }}</div>
          <div class="h1 margin--bottom-2" v-if="event.introHeadline">{{ event.introHeadline }}</div>
          <div v-html="event.introText ? event.introText.html : ''"></div>
        </div>
        <!-- tickets -->
        <div class="margin--top-2 margin--bottom-8 event-tickets"
             v-if="!loading && (event.ticketOverviewImage || (event.ticketConfig && Object.keys(event.ticketConfig).length && event.ticketDiscountChild && event.ticketDiscountTeenager && event.ticketDiscountHandicap))"
             :class="{ 'view': tickets.visible }"
             v-scroll:in="() => { tickets.visible = true; tickets.loaded = true; }"
             v-scroll:out="() => tickets.visible = false">
          <div class="bg-track text-white">
            <div class="container-fluid padding--top-3 padding--bottom-8">
              <div class="h4 font-weight--light text-italic margin--bottom-1 fade-in-child fade-in--bottom">
                {{ $t('raceCalendar.tickets') }}
              </div>
              <div class="h2 fade-in-child fade-in--bottom delay--200" v-text="event.ticketOverviewImageTitle ? event.ticketOverviewImageTitle : $t('raceCalendar.ticketHeadline')"/>
            </div>
          </div>
          <div class="container-fluid">
            <div class="bg-white event-tickets__white fade-in-child fade-in--bottom delay--500">
              <div class="overflow--hidden position-relative event-tickets__image">
                <template v-if="event.ticketOverviewLink">
                  <a :href="event.ticketOverviewLink">
                    <background-image
                      v-if="event.ticketOverviewImage"
                      :scale="'original'"
                      :url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:1200,fit:crop/output=format:jpg/quality=value:70/${event.ticketOverviewImage.handle}`"
                      :loaded="tickets.loaded"
                      :preloadWhite="true"/>
                  </a>
                </template>
                <template v-else>
                  <background-image
                    v-if="event.ticketOverviewImage"
                    :scale="'original'"
                    :url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:1200,fit:crop/output=format:jpg/quality=value:70/${event.ticketOverviewImage.handle}`"
                    :loaded="tickets.loaded"
                    :preloadWhite="true"/>
                </template>
              </div>
              <tickets
                v-if="event.ticketConfig && Object.keys(event.ticketConfig).length"
                :key="event.id"
                :categories="event.ticketConfig"
                :discount-child="event.ticketDiscountChild"
                :discount-teenager="event.ticketDiscountTeenager"
                :discount-handicap="event.ticketDiscountHandicap"/>
            </div>
          </div>
        </div>
        <!-- highlights -->
        <div class="container-fluid margin--top-2 margin--bottom-8" v-if="!loading && event.videoId"
             :class="{ 'view': highlightVideo.visible }"
             v-scroll:in="() => { highlightVideo.visible = true; highlightVideo.loaded = true; }"
             v-scroll:out="() => highlightVideo.visible = false">
          <div class="text-align--center">
            <div class="text-uppercase text-condensed font-weight--light margin--bottom-1 margin--top-3">
              {{ event.videoTopline }}
            </div>
            <div class="h3 margin--bottom-2">
              {{ event.videoHeadline }}
            </div>
            <div class="box-shadow">
              <div class="position-relative overflow--hidden">
                <youtube :video-id="event.videoId"/>
              </div>
            </div>
          </div>
        </div>
        <!-- supporting series -->
        <supporting-series
          :entries="event.supportingSerieses"
          v-if="!loading && event.supportingSerieses && event.supportingSerieses.length > 0"/>
        <!-- timetable -->
        <timetable
           v-if="!loading && event && event.timetable && event.timetable.length"
           :title="event.name"
           :pdf="$i18n.locale.toLowerCase() === 'de' ? event.timetablePdfDe : event.timetablePdfEn"
           :entries="event.timetable"/>
        <!-- on site events -->
        <div class="margin--top-2 margin--bottom-8 supporting-program"
             v-if="!loading && event.supportingProgrammes && event.supportingProgrammes.length > 0"
             :class="{ 'view': program.visible }"
             v-scroll:in="() => { program.visible = true; program.loaded = true; }"
             v-scroll:out="() => program.visible = false">
          <div class="container-fluid">
            <div class="h4 font-weight--light text-italic margin--bottom-1 fade-in-child fade-in--bottom">
              {{ $t('raceCalendar.dtmEvents') }}
            </div>
            <div class="h2 margin--bottom-2 fade-in-child fade-in--bottom delay--200">
              {{ $t('raceCalendar.programOnSite') }}
            </div>
            <swiper :ref="program.swiper" :options="program.options">
              <!-- Slides -->
              <swiper-slide v-for="item in event.supportingProgrammes" :key="item.name">
                <div class=" fade-in-child fade-in--bottom delay--500">
                  <div class="bgimg--hover">
                    <div class="fade-in-child">
                      <background-image
                        :scale="'16-9'"
                        :url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:800,fit:crop/output=format:jpg/quality=value:70/${item.image.handle}`"
                        :loaded="program.loaded"/>
                    </div>
                    <div class="padding-1">
                      <div class="fade-in-child delay--100 h4">
                        {{ item.headline }}
                      </div>
                      <div class="fade-in-child delay--200">
                        <p>{{ item.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-pagination"></div>
            </swiper>
          </div>
        </div>
        <event-benefit
          v-if="event && event.moduleBenefits"
          :key="event.id"
          :module="event.moduleBenefits"/>
        <channel-list
          v-if="event && event.country && event.country.countryCode"
          :key="event.id"
          :country-code="event.country.countryCode"/>
        <racetrack-records
          v-if="event && event.racetrack && event.racetrack.records"
          :records="event.racetrack.records"
          :key="event.id"/>
        <racetrack-informations
          v-if="event && event.racetrack && event.racetrack.trackinformations"
          :key="event.id"
          :informations="event.racetrack.trackinformations"
          :poi-map="event.racetrack.poiMap"
          :poi-map-alt="event.racetrack.poiMapAlt"
          :poi-map-title="event.racetrack.poiMapTitle"
          :pois="event.racetrack.pois"/>
        <address-image
          v-if="event && event.addressImage"
          :key="event.id"
          :image="event.addressImage"
          :image-alt="event.addressImageAlt"
          :image-title="event.addressImageAlt"
          :route="event.addressRouteUrl"/>
        <info-boxes
          v-if="event && event.infoBoxesTopline && event.infoBoxesHeadline && event.infoBoxes.length > 0"
          :key="event.id"
          :class="'container-fluid'"
          :headline="event.infoBoxesHeadline"
          :topline="event.infoBoxesTopline"
          :data="event.infoBoxes"/>
        <racetrack-city
          v-if="event && event.racetrack && event.racetrack.city"
          :key="event.id"
          :city="event.racetrack.city"/>
      </div>
      <json-ld-event
        v-if="!loading && event"
        :url="$route.fullPath"
        :start-date="(event && event.startTime) ? event.startTime : null"
        :end-date="(event && event.endTime) ? event.endTime : null"
        :name="event.name"
        :address="event.address"
        :city="event.city"
        :country="event.country.name"
        :map-url="event.addressRouteUrl"
        :image-url="`https://${Settings.region}.graphassets.com/${Settings.environmentId}/resize=w:700,fit:crop/output=format:jpg/quality=value:70/${event.headerImage.handle}`"
        :postal-code="event.postCode"
        :description="event.metaDescription"/>
    </div>
    <div v-if="error">
      <page-not-found></page-not-found>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import PageHeader from '../components/layout/PageHeader';
import JsonLdEvent from '../components/json-ld/JsonLdEvent';
import BackgroundImage from '../components/layout/BackgroundImage';
import Youtube from '../components/Youtube';
import Tickets from '../components/events/Tickets';
import MetaData from '../components/MetaData';
import AddressImage from '../components/events/AddressImage';
import ChannelList from '../components/events/ChannelList';
import EventBenefit from '../components/events/EventBenefit';
import RacetrackRecords from '../components/events/RacetrackRecords';
import RacetrackInformations from '../components/events/RacetrackInformations';
import Countdown from '../components/layout/Countdown';
import PageNotFound from './PageNotFound';
import SupportingSeries from '../components/events/SupportingSeries';
import Loader from '../components/layout/Loader';
import Timetable from '../components/events/Timetable';
import RacetrackCity from '../components/events/RacetrackCity';
import Settings from '../config/settings';
import InfoBoxes from '@/components/InfoBoxes';
import EventSponsorBanner from '@/components/events/EventSponsorBanner';

export default {
  name: 'RaceCalendarDetail',
  components: {
    EventSponsorBanner,
    InfoBoxes,
    RacetrackCity,
    Timetable,
    Loader,
    SupportingSeries,
    PageNotFound,
    Countdown,
    RacetrackInformations,
    RacetrackRecords,
    EventBenefit,
    ChannelList,
    AddressImage,
    MetaData,
    Tickets,
    Youtube,
    BackgroundImage,
    JsonLdEvent,
    PageHeader
  },
  data () {
    return {
      Settings: Settings,
      error: false,
      loading: true,
      slug: this.$route.params.slug,
      event: null,
      highlightVideo: {
        embed: false,
        visible: false,
        loaded: false
      },
      program: {
        visible: false,
        loaded: false,
        swiper: false,
        options: {
          slidesPerView: 'auto',
          spaceBetween: 15,
          breakpoints: {
            // when window width is >= 320px
            576: {
              slidesPerView: 2,
              spaceBetween: 15,
              autoplay: true
            },
            // when window width is >= 480px
            980: {
              slidesPerView: 3,
              spaceBetween: 15,
              autoplay: true
            }
          }
        }
      },
      tickets: {
        visible: false,
        loaded: false,
        swiper: false,
        options: {
          autoplay: {
            delay: 4000
          },
          slidesPerView: 'auto',
          spaceBetween: 15,
          breakpoints: {
            576: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            980: {
              slidesPerView: 4,
              spaceBetween: 15,
              autoplay: true
            }
          }
        }
      }
    };
  },
  methods: {
    async fetchEventDetail () {
      await this.$store.dispatch('page/raceSeriesLoaded', true);
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=eventDetails&slug=${this.slug}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.events.forEach((event) => {
            event.viewPort = false;
            event.days = this.displayDays(event.startTime, event.endTime);
            event.months = this.displayMonths(event.startTime, event.endTime);
            event.links = [];
            if (event.ticketUrl) {
              event.links.push({ url: event.ticketUrl, title: this.$t('raceCalendar.tickets') });
            }
            this.loading = false;
          });
          this.event = response.events[0];
          this.event.localizations.forEach(localization => {
            this.$store.commit(AppConst.ADD_LOCALE, localization.locale);
          });
          this.$store.commit(AppConst.ADD_LOCALIZATIONS, this.event.localizations);
          this.$store.commit('page/SET_RACESERIES', (['DTM']));
          this.$store.dispatch('page/raceSeriesLoaded', false);
        }).catch(() => {
          this.error = true;
        });
    },
    displayDays: function (start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return ('00' + startDate.getDate()).slice(-2) + '.' + (startDate.getDate() !== endDate.getDate() ? ' - ' + ('00' + endDate.getDate()).slice(-2) + '.' : '');
    },
    displayMonths: function (start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return (this.$i18n.d(startDate, 'monthLong') + (startDate.getMonth() !== endDate.getMonth() ? ' / ' + this.$i18n.d(endDate, 'monthLong') : ''));
    }
  },
  watch: {
    '$route.params.slug': function () {
      window.scrollTo(0, 0);
      this.fetchEventDetail();
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    this.fetchEventDetail();
  },
  beforeDestroy () {
    this.$store.commit(AppConst.REMOVE_LOCALE, 'ru');
    this.$store.commit(AppConst.REMOVE_LOCALE, 'nl');
    this.$store.commit(AppConst.REMOVE_LOCALE, 'it');
    this.$store.commit(AppConst.REMOVE_LOCALE, 'sv');
  }
};
</script>

<style lang="scss" scoped>
  .supporting-program {
    padding-bottom: $gap * 3;
  }
  .event-tickets {
    padding-bottom: $gap * 3;

    &__white {
      margin-top: $gap * -6;
    }

    &__image {
      margin: 0 $gap * 5 $gap * 2;
    }
  }
  @media (max-width: map_get($grid-breakpoints, 'lg')) {
    .supporting-series {
      &__white {
        padding-left: $gap * 3;
        padding-right: $gap * 3;
      }
    }
    .event-tickets {
      &__image {
        margin: 0 $gap * 2 $gap * 2;
      }
    }
  }
  @media (max-width: map_get($grid-breakpoints, 'sm')) {
    .supporting-series {
      &__white {
        padding-left: $gap;
        padding-right: $gap;
      }
      &__text {
        margin: -$gap $gap 0;
      }
    }
    .supporting-program {
      .swiper-slide {
        width: 80vw;
      }
    }
    .event-tickets {
      &__image {
        margin: 0 0 $gap * 2;
      }
    }
  }
</style>
